const innerConfig = {
  baseUrl: process.env.VITE_APP_BACKEND_BASE_URL,
  pythonBaseUrl: process.env.VITE_APP_PYTHON_BACKEND_BASE_URL,
  oneClickBaseUrl: process.env.VITE_APP_ONE_CLICK_BACKEND_BASE_URL,
};

export const config = innerConfig;
export const instituteAppApiPath = `${config.baseUrl}/instituteApp/institute`;
export const stripeCheckoutApiPath = `${config.baseUrl}/faangpath/premiumSubscriptionCheckout`;
export const webappErrorReportApiPath = `${config.baseUrl}/faangpath/error/webApp`;
export const jobTrackerApiPath = `${config.baseUrl}/jobTracker`;
export const faangpathApiPath = `${config.baseUrl}/faangpath`;
export const saveQueryApiPath = `${jobTrackerApiPath}/saveQuery`;
export const saveAllQueriesPath = `${jobTrackerApiPath}/queries`;
export const userRoleApiPath = `${jobTrackerApiPath}/user-role`;
export const userDetailApiPath = `${jobTrackerApiPath}/userdetail`;
export const miscApiPath = `${config.baseUrl}/faangpath/misc`;
export const shareQueryApiPath = `${jobTrackerApiPath}/shareQuery`;
export const likeApiPath = `${jobTrackerApiPath}/like`;
export const queryFeedbackApiPath = `${jobTrackerApiPath}/queryFeedback`;
export const linkedinProfileApiPath = `${config.baseUrl}/chromeExtension/linkedinProfile`;
export const aiServiceBasePath = `${config.baseUrl}/aiTools`;
export const aiCoverLetterApiPath = `${aiServiceBasePath}/generate/cover-letter`;
export const linkedinAboutApiPath = `${aiServiceBasePath}/generate/linkedin/about`;
export const elevatorPitchApiPath = `${aiServiceBasePath}/generate/elevatorPitch`;
export const linkedinHeadlineApiPath = `${aiServiceBasePath}/generate/linkedin/headline`;
export const aiPostApiPath = `${aiServiceBasePath}/generate/linkedin-post`;
export const aiParseApiPath = `${aiServiceBasePath}/generate/parse`;
export const skillmatchApiPath = `${jobTrackerApiPath}/skillmatch`;
export const imageUrlEndpoint = 'https://ik.imagekit.io/6jgghqyrto/';
export const fastApiDomainPath = `https://fastapi-chatbot-389115.el.r.appspot.com`;
