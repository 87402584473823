import { BugOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styles from './Fallback.module.css';
import err from './images/download.png';
function Fallback() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '15rem' }}>
      <img
        src={err}
        height={100}
        alt="error icon"
        style={{ marginRight: '4px', marginBottom: '5rem' }}
      />
      <h1>Oops, something went wrong.</h1>
      <p>Please try again later or contact support.</p>
      <div style={{ marginTop: 24 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className={`${styles.createbtn} btnContainer`}
            onClick={() => window.location.reload()}
          >
            <ReloadOutlined /> Refresh
          </Button>

          <Button className={`${styles.createbtn1} btnContainer reportAnIssue`}>
            <BugOutlined /> Report an Issue
          </Button>
        </div>
        {/* <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}
        >
          <span
            style={{ margin: '0px 4px', color: '#aaa', fontSize: '1.2rem' }}
          >
            or
          </span>
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}
        >
          <Input placeholder="johndoe@example.com" style={{ maxWidth: 200 }} />
          <Button
          type = "primary"
            onClick={openNotification}
            style={{ marginLeft: 8 }}
          >
            <BellOutlined />
            <span  style={{ marginLeft: 4 }}>Get Notified</span>
          </Button>
        </div> */}
      </div>
    </div>
  );
}

export default Fallback;
