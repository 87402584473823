export const InstituteFeatureFlags = {
  'cf-onboarding-checklist': 'cf-onboarding-checklist',
  'resume-builder': 'resume-builder',
  linkedin: 'linkedin',
  aiTools: 'aiTools',
  boards: 'boards',
  contacts: 'contacts',
  documents: 'documents',
  community: 'community',
  'hiring-search': 'hiring-search',
  'institute-jobs': 'institute-jobs',
  'download-extension-button': 'download-extension-button',
  intercom: 'intercom',
  'explore-features': 'explore-features',
  'institute-resources': 'institute-resources',
  'help-center': 'help-center',
};
